import { GatsbyImage } from "gatsby-plugin-image";
import * as React from "react";
import { classNames } from "../../utils";

export default function Features(props) {
  const { barCharts, insights, pieCharts, drillDown, customerDataCenter, integrationsClear } =
    props;
  console.log(barCharts, insights, pieCharts);
  const features = [
    {
      title: "Smart Cohorts",
      stacked: false,
      shadow: true,
      text: (
        <>
          <Bold>Build cohorts quickly and easily.</Bold> Use our no-code cohort
          builder or write SQL to define and compare different customer groups.
          <br />
          <br />
          <Bold>Automatically understand how cohorts differ.</Bold> Cotera
          searches through your data and surfaces the most statistically
          significant differences between your specified cohorts. Don't rely on
          hunches - Cotera will compare and surface everything relevant.
        </>
      ),
      image: barCharts.childImageSharp.gatsbyImageData,
      images: [
        insights.childImageSharp.gatsbyImageData,
        barCharts.childImageSharp.gatsbyImageData,
        pieCharts.childImageSharp.gatsbyImageData,
      ],
    },
    {
      title: "Dive Deeper",
      stacked: false,
      shadow: true,
      text: (
        <>
          <Bold>Drill down into the automated insights.</Bold>  When you spot
          something interesting in the visualizations, just select the appropriate
          part of the chart and Cotera will drill down into the details for you.
          <br />
          <br />
          <Bold>Create a fast feedback loop.</Bold>  You can feed your selections
          back into your analysis to dig in further, or save and track how they
          change over time.
        </>
      ),
      image: drillDown.childImageSharp.gatsbyImageData,
    },
    {
      title: "Customer Data Center",
      stacked: false,
      shadow: true,
      text: (
        <>
          <Bold>A complete customer overview.</Bold> Cotera can also build
          from the bottom up - quickly view a customer profile and find other
          users with similar attributes.
          <br />
          <br />
          <Bold>Data warehouse native.</Bold> Cotera sits on top of your data
          warehouse -- this makes it incredibly easy and safe to use relevant
          data without having it leave your servers.
        </>
      ),
      image: customerDataCenter.childImageSharp.gatsbyImageData,
    },
    {
      title: "The World's First Virtual Feature Store",
      stacked: false,
      shadow: false,
      text: (
        <>
          <Bold>Ingest from anywhere.</Bold> Cotera plugs in to your
          data warehouse and can ingest data from anywhere -- whether that be
          a CRM, marketing application, or a database. Create a "Virtual
          Feature Store" that maps all of your data sources and can quickly
          re-compile customer data.
          <br />
          <br />
          <Bold>Export to anything.</Bold> The Cotera VFS can smart-segment and
          output data to most of your marketing tools, with more being added every
          day. Use your favorite tools to track how cohorts are doing,
          market to them in various ways, and so much more.
        </>
      ),
      image: integrationsClear.childImageSharp.gatsbyImageData,
    },
  ];

  return (
    <div className="relative">
      <div className="divide-y divide-gray-100 border-t border-b border-gray-100">
        {features.map((f, i) => {
          let Feature;
          if (f.stacked) {
            console.log("stacked");
            Feature = i % 2 === 0 ? StackedLeftFeature : StackedRightFeature;
          } else {
            Feature = i % 2 === 0 ? LeftFeature : RightFeature;
          }
          return <Feature key={i} {...props} {...f} />;
        })}
      </div>
    </div>
  );
}

function Bold({ children }) {
  return <span className="font-bold text-gray-800">{children}</span>;
}

function LeftFeature(props) {
  const { title, text, image, shadow } = props;
  return (
    <div className="relative flex items-center min-h-screen bg-gray-50">
      <div className="grid space-y-12 lg:grid-cols-9">
        <div className="flex lg:col-span-4 lg:order-2">
          <FeatureDescription title={title} text={text} />
        </div>
        <div className="flex lg:col-span-5">
          <FeatureImage image={image} title={title} side="left" shadow={shadow} />
        </div>
      </div>
    </div>
  );
}

function RightFeature(props) {
  const { title, text, image, shadow } = props;
  return (
    <div className="relative flex items-center min-h-screen">
      <div className="grid space-y-12 lg:grid-cols-9">
        <div className="flex lg:col-span-4">
          <FeatureDescription title={title} text={text} />
        </div>
        <div className="flex lg:col-span-5">
          <FeatureImage image={image} title={title} side="right" shadow={shadow} />
        </div>
      </div>
    </div>
  );
}

function StackedLeftFeature(props) {
  const { title, text, images } = props;
  return (
    <div className="relative flex items-center min-h-screen bg-gray-50">
      <div className="grid space-y-12 lg:grid-cols-9">
        <div className="flex lg:col-span-4 lg:order-2">
          <FeatureDescription title={title} text={text} />
        </div>
        <div className="flex lg:col-span-5">
          <StackedFeatureImage images={images} title={title} side="left" />
        </div>
      </div>
    </div>
  );
}

function StackedRightFeature(props) {
  const { title, text, images } = props;
  return (
    <div className="relative flex items-center min-h-screen">
      <div className="grid space-y-12 lg:grid-cols-9">
        <div className="flex lg:col-span-4">
          <FeatureDescription title={title} text={text} />
        </div>
        <div className="flex lg:col-span-5">
          <StackedFeatureImage images={images} title={title} side="right" />
        </div>
      </div>
    </div>
  );
}

function FeatureDescription({ title, text }) {
  return (
    <div className="max-w-lg m-auto px-8 md:px-10">
      <h2 className="text-4xl text-center lg:text-left lg:text-5xl font-extrabold tracking-tight text-gray-900 mb-8">
        {title}
      </h2>
      <p className="text-gray-600 font-normal text-xl text-center lg:text-left">
        {text}
      </p>
    </div>
  );
}

function FeatureImage({ image, side, title, shadow }) {
  return (
    <div className="flex-grow min-h-3/4">
      <FeatureBackground side={side}>
        <GatsbyImage
          alt={`${title} feature`}
          image={image}
          className={classNames(
            "block h-5/6 ring-black ring-opacity-5 rounded-md lg:rounded-none",
            shadow ? "shadow-2xl drop-shadow-2xl" : "",
            side === "left" ? "lg:rounded-r-3xl" : "lg:rounded-l-3xl"
          )}
        />
      </FeatureBackground>
    </div>
  );
}

// const positions = ["top-0 left-0", "top-1/3 left-1/3", "top-2/3 left-2/3"];
const positions = [
  "translate-x-0 translate-y-0",
  // "translate-x-1/4 translate-y-1/4",
  // "translate-x-2/4 translate-y-2/4",
  "translate-x-8 translate-y-8 lg:translate-x-16 lg:translate-y-16",
  "translate-x-16 translate-y-16 lg:translate-x-32 lg:translate-y-32",
  // "top-2/3 left-2/3",
];

function StackedFeatureImage({ images, side, title }) {
  return (
    <div className="flex-grow min-h-3/4 relative">
      <FeatureBackground side={side}>
        <GatsbyImage
          alt={`${title} feature`}
          image={images[0]}
          className={classNames(
            "opacity-0",
            "block h-5/6 ring-0 rounded-md lg:rounded-none",
            side === "left" ? "lg:rounded-r-3xl" : "lg:rounded-l-3xl"
          )}
        />
        {images.map((img: any, i: any) => {
          return (
            <GatsbyImage
              alt={`${title} ${i} feature`}
              image={img}
              className={classNames(
                "absolute -top-12 md:-top-20 -left-1/4",
                positions[i],
                "scale-50 block shadow-2xl drop-shadow-2xl ring-black ring-opacity-5",
                "rounded-md lg:rounded-3xl"
                // side === "left" ? "lg:rounded-r-3xl" : "lg:rounded-l-3xl"
              )}
            />
          );
        })}
      </FeatureBackground>
    </div>
  );
}

function FeatureBackground(props) {
  const css = classNames(
    "relative flex items-center h-full w-full py-6 lg:py-16 px-8 lg:px-0",
    props.side === "left"
      ? "lg:pr-20 lg:rounded-r-3xl"
      : "lg:pl-20 lg:rounded-l-3xl"
  );
  return (
    <div
      className={css}
      style={{
        background:
          "linear-gradient(-45deg, #f97316, #f43f5e, #6366f1, #10b981)",
        backgroundSize: "400% 400%",
        animation: "gradient 15s ease infinite",
      }}
    >
      <div className="rounded-3xl">{props.children}</div>
    </div>
  );
}
