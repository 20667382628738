import {
  CogIcon,
  LightBulbIcon,
  LightningBoltIcon,
  ServerIcon,
  UserIcon,
  UsersIcon,
} from "@heroicons/react/outline";
import React from "react";

const features = [
  {
    name: "Build Cohorts",
    description:
      "Build cohorts of your customer base in an intuitive, visual way without asking a developer - or drop down into SQL if you want to.",
    icon: UsersIcon,
  },
  {
    name: "Automated Insights",
    description:
      "Automatically visualize behavioral differences between cohorts. Select two cohorts and Cotera will surface the most interesting differences in their behavior.",
    icon: LightningBoltIcon,
  },
  {
    name: "Smart Cohorts",
    description:
      "Spot something interesting in the cohort comparison? Simply select it to create a new cohort to and feed it back into your analysis.",
    icon: LightBulbIcon,
  },
  {
    name: "Customer Data Center",
    description:
      "Understand your customers from the top-down and bottom-up. Cotera makes it easy to drill all the way down to view the data for a single customer.",
    icon: UserIcon,
  },
  {
    name: "Take Action",
    description:
      "Go beyond building cohorts and automate action based on them. We integrate with tools like braze to allow you to automate campaigns in any way you like.",
    icon: CogIcon,
  },
  {
    name: "Data Team Friendly",
    description:
      "Your data team will love Cotera. We integrate with the tools they already use and your data warehouse, so you don't need to send any data to us.",
    icon: ServerIcon,
  },
];

const Overview: React.FC = () => {
  return (
    <div className="relative bg-white py-16 sm:py-24 lg:py-32">
      <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
        <h2 className="text-base font-semibold uppercase tracking-wider text-indigo-600">
          go further
        </h2>
        <p className="mt-2 text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
          Everything you need to understand your customers
        </p>
        <p className="mx-auto mt-5 max-w-prose text-xl text-gray-500">
          Cotera has everything you need to go beyond descriptive analytics and
          figure out what really drives customer behavior.
        </p>
        <div className="mt-12">
          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
            {features.map((feature) => (
              <div key={feature.name} className="pt-6">
                <div className="flow-root rounded-lg bg-gray-50 px-6 pb-8">
                  <div className="-mt-6">
                    <div>
                      <span className="inline-flex items-center justify-center rounded-md bg-indigo-500 p-3 shadow-lg">
                        <feature.icon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </span>
                    </div>
                    <h3 className="mt-8 text-lg font-medium tracking-tight text-gray-900">
                      {feature.name}
                    </h3>
                    <p className="mt-5 text-base text-gray-500">
                      {feature.description}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overview;
