import { Location } from "@reach/router";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setEmail } from "../../state/store";
import { gtm } from "../../tracking/gtm";
import { classNames } from "../../utils";
import Typewriter from "typewriter-effect";

const LOOM_ID = "65cfb1cad9004b13aa1bb28b5fea67a3";

export default function Hero() {
  return (
    <div
      className={classNames("min-h-screen relative pb-8 max-w-7xl", "lg:pb-24")}
    >
      <div className="pt-4 lg:pt-16">
        <HeroText />
      </div>
      <div className="flex flex-col w-full">
        <DemoVideo />
      </div>
    </div>
  );
}

function HeroText() {
  const [cursor, setCursor] = useState("|");
  const [word, setWord] = useState("");
  useEffect(() => {
    const typing = () => {
      setWord(word + "a");
      setTimeout(typing, 500);
    };
    const cursor = () => {
      if (cursor === "|") {
        setCursor(" ");
      } else {
        setCursor("|");
      }
      setTimeout(cursor, 200);
    };
    typing();
    cursor();
  }, []);
  return (
    <div
      className={classNames(
        "flex flex-col mx-auto text-center px-4",
        "sm:max-w-4xl sm:px-8",
        "xl:max-w-5xl xl:pb-24 xl:flex xl:items-center"
      )}
    >
      <h1 className="mt-4 text-4xl tracking-tight font-extrabold sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
        <span className="block py-2 text-white opacity-90">
          Understand what drives
        </span>
        <Typewriter
          options={{
            loop: true,
            wrapperClassName: "text-white opacity-90",
            cursorClassName: "Typewriter__cursor text-white opacity-90",
          }}
          onInit={(typewriter) => {
            ["retention", "referrals", "churn", "upgrades", "behavior"].forEach(
              (w) => {
                typewriter.typeString(w + ".");
                typewriter.pauseFor(500);
                typewriter.deleteChars(w.length + 1);
                typewriter.pauseFor(200);
              }
            );
            typewriter.start();
          }}
        />
      </h1>
      <div className="px-4 md:px-24 lg:px-32 xl:px-40">
        <SubText />
      </div>
      <div className="max-w-2xl mx-auto">
        <Cta />
      </div>
    </div>
  );
}

function SubText() {
  return (
    <p className="mt-3 text-lg font-light text-white sm:mt-5 opacity-80">
      Cotera surfaces differences between your customer segments, so that you
      can build intelligent campaigns that really work.
    </p>
  );
}

function Cta() {
  const dispatch = useDispatch();

  return (
    <div className="mt-10 sm:mt-12 w-full">
      <form
        action="https://supersheets.us5.list-manage.com/subscribe/post?u=ef4f58698298eaffc50ff19af&amp;id=cfd3a6f2b4"
        method="post"
        id="mc-embedded-subscribe-form"
        name="mc-embedded-subscribe-form"
        className="validate"
        target="_blank"
        noValidate
      >
        <div className="sm:flex">
          <div className="flex-1 shadow-2xl">
            <label htmlFor="email" className="sr-only">
              Email address
            </label>
            <input
              id="mce-EMAIL"
              name="EMAIL"
              type="email"
              placeholder="Enter your email"
              className="block w-64 px-4 py-3 rounded-md border-0 text-sm text-gray-900 opacity-95 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-200 focus:ring-offset-indigo-400"
              onChange={(e) => {
                dispatch(setEmail(e.target.value));
              }}
            />
          </div>
          <div className="mt-3 sm:mt-0 sm:ml-3 shadow-2xl">
            <button
              type="submit"
              value="Submit"
              name="submit"
              id="mc-embedded-subscribe"
              // className="block w-full py-3 px-4 border-2 border-indigo-50 rounded-md shadow text-sm font-medium opacity-95 text-indigo-50 hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-200 focus:ring-offset-indigo-400"
              className="inline-flex items-center px-4 py-2.5 text-sm font-medium rounded-md border-2 border-white text-white bg-none hover:bg-indigo-700"
              onClick={() => {
                gtm({
                  event: "submit_hero_cta",
                  action: "click",
                  properties: {
                    location: "hero",
                  },
                });
              }}
            >
              Join waitlist
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

function DemoVideo() {
  return (
    <div className="mx-auto w-full max-w-5xl px-4 my-16">
      <div
        className={classNames(
          "relative h-0 rounded-xl overflow-hidden max-w-full shadow-2xl shadow-indigo-200 ring-1 ring-indigo-400 ring-opacity-70 bg-indigo-100",
          "xl:scale-125"
        )}
        style={{
          paddingBottom: "56.25%",
        }}
      >
        <iframe
          title="Supersheets demo"
          src={`https://www.loom.com/embed/${LOOM_ID}?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true`}
          frameBorder="0"
          webkitallowfullfcreen="true"
          mozallowfullscreen="true"
          allowFullScreen
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          className="absolute w-full h-full"
        />
      </div>
    </div>
  );
}
